import { ReactNode } from "react";
import { useOnFilesChange, useStore } from "./state";

import Dropzone from "./Dropzone";
import Preview from "./Preview";
import { useSnapshot } from "valtio";
import useAfterUnmount from "../../../web/src/hooks/useAfterUnmount";

export default function FilePicker({
  allowedFileConfig,
  prompt,
  onCancel,
  onChange,
  onInvalidFileDrop,
  clearOnUnmount = false,
  allowMultipleDocumentsPreview = false,
}: {
  allowedFileConfig: { extensions: string[]; maxFiles: number };
  prompt: ReactNode;
  onCancel?(): void;
  onChange?(files: File[]): void;
  onInvalidFileDrop?(message: string): void;
  clearOnUnmount?: boolean;
  allowMultipleDocumentsPreview?: boolean;
}) {
  const store = useStore();
  const { files } = useSnapshot(store);

  const maxAllowedFiles = allowedFileConfig.maxFiles;

  useOnFilesChange((files) => onChange?.(files));
  useAfterUnmount(() => {
    if (!clearOnUnmount) return;
    store.files = [];
  });

  return (
    <Dropzone
      allowedFileExtensions={allowedFileConfig.extensions}
      maxAllowedFiles={maxAllowedFiles}
      onCancel={onCancel}
      onInvalidFileDrop={onInvalidFileDrop}
      prompt={prompt}
    >
      <Preview
        files={files}
        allowMultipleDocumentsPreview={allowMultipleDocumentsPreview}
      />
    </Dropzone>
  );
}
