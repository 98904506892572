import {
  type DragEvent,
  type MutableRefObject,
  type ReactNode,
  useCallback,
  useRef,
  ChangeEvent,
} from "react";
import classnames from "classnames";
import { useOnFilesChange, useStore } from "../state";
import {
  mergeFileLists,
  partitionFiles,
  preventDefault,
  useOnPastedFiles,
} from "./utils";

import XButton from "../XButton";
import Nav from "./Nav";
import styles from "./Dropzone.module.css";
import { useSnapshot } from "valtio";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";

const INPUT_NAME = "classdojo_drop-zone_file-input";

export default function Dropzone({
  allowedFileExtensions,
  className,
  maxAllowedFiles,
  onCancel,
  onInvalidFileDrop,
  prompt,
  children,
}: {
  allowedFileExtensions: string[];
  className?: string;
  maxAllowedFiles: number;
  prompt: ReactNode;
  onCancel?(): void;
  onInvalidFileDrop?(message: string): void;
  children: ReactNode;
}) {
  const fileInput =
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;
  const store = useStore();
  const snap = useSnapshot(store);

  const allowMultiple = maxAllowedFiles > 1;

  const onFiles = useCallback(
    (filesOrEvent: File[] | ChangeEvent<HTMLInputElement>) => {
      const newFiles = Array.isArray(filesOrEvent)
        ? filesOrEvent
        : Array.from(filesOrEvent.target.files ?? []);
      const { valid, invalid } = partitionFiles(
        newFiles,
        allowedFileExtensions
      );
      if (invalid.length > 0) {
        onInvalidFileDrop?.(autoTranslate("That file type is unsupported."));
      }

      const allFiles = [...store.files, ...valid];

      if (allowMultiple && allFiles.length > maxAllowedFiles) {
        onInvalidFileDrop?.(
          autoTranslate(
            "You can add up to __maxAllowedFiles__ photos / videos.",
            { maxAllowedFiles: maxAllowedFiles.toString() }
          )
        );
      }

      if (valid.length > 0) {
        if (allowMultiple) {
          store.addFiles(valid, maxAllowedFiles);
        } else {
          store.setFiles(valid);
        }
      }
    },
    [
      store,
      allowedFileExtensions,
      onInvalidFileDrop,
      maxAllowedFiles,
      allowMultiple,
    ]
  );

  useOnPastedFiles(onFiles);

  const handleDrop = useCallback(
    (event: DragEvent<HTMLFormElement>) => {
      event.preventDefault();
      store.handleDrag("drop");

      onFiles(Array.from(event.dataTransfer?.files ?? []));
    },
    [store, onFiles]
  );

  // syncronize file input with changes in file state
  useOnFilesChange(
    useCallback(
      (files: File[]) => {
        if (fileInput.current) {
          fileInput.current.files = mergeFileLists(files);
        }
      },
      [fileInput]
    )
  );

  const handleCancel = useCallback(() => {
    if (fileInput.current) {
      fileInput.current.value = "";
      store.files = [];
    }
    onCancel?.();
  }, [store, fileInput, onCancel]);

  const shouldShowPrompt = snap.files.length === 0;

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        fileInput.current?.click();
      }
    },
    [fileInput]
  );

  return (
    <form
      id="file-upload-form"
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      name={INPUT_NAME}
      onDragEnter={() => store.handleDrag("enter")}
      onDragLeave={() => store.handleDrag("leave")}
      onDragOver={preventDefault}
      onDrop={handleDrop}
      className={classnames(
        styles.main,
        { [styles.dragging]: snap.isDragging },
        className
      )}
    >
      {shouldShowPrompt ? (
        <label
          data-name="file_upload.dropzone.prompt"
          className={styles.promptContainer}
          htmlFor={INPUT_NAME}
        >
          {onCancel && (
            <XButton className={styles.cancelButton} onClick={handleCancel} />
          )}
          <div
            // eslint-disable-next-line @web-monorepo/no-jsx-role-button
            role="button"
            tabIndex={0}
            className={styles.prompt}
            onKeyDown={handleKeyDown}
          >
            {prompt}
          </div>
        </label>
      ) : (
        <div className={styles.children}>
          <Nav
            maxAllowedFiles={maxAllowedFiles}
            onCancel={onCancel ? handleCancel : undefined}
            fileInputName={INPUT_NAME}
          />
          {children}
        </div>
      )}
      <input
        disabled={snap.files.length >= maxAllowedFiles}
        multiple={allowMultiple}
        accept={allowedFileExtensions.join(",")}
        onChange={onFiles as () => void}
        ref={fileInput}
        type="file"
        className={classnames(styles.input, {
          [styles.inputHidden]: !shouldShowPrompt,
        })}
        name={INPUT_NAME}
        id={INPUT_NAME}
        tabIndex={-1}
      />
    </form>
  );
}
