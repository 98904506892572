// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconThumbsup = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5162 9.82121C21.2999 9.82121 21.8287 10.4775 21.7912 11.2687L21.7987 11.2725C21.7687 11.9362 21.3337 12.465 20.7074 12.5662C20.6362 12.5775 20.6062 12.6225 20.6662 12.6487C21.0899 12.8325 21.3224 13.2712 21.3224 13.7812V13.9012C21.3224 14.58 20.8762 15.1275 20.1974 15.1275H19.9537C19.9537 15.1275 19.8599 15.165 19.9237 15.1912C20.3512 15.375 20.5912 15.8137 20.5912 16.3275C20.5912 17.0062 20.1449 17.5537 19.4662 17.5537H18.9337C18.9337 17.5537 18.8474 17.6062 18.9037 17.64C19.2487 17.85 19.4212 18.2475 19.4212 18.7012V18.8062C19.4212 19.485 19.0087 19.9162 18.2962 20.0325C17.5012 20.1637 15.3374 20.3512 13.3799 20.0325C11.3024 19.695 10.1962 19.0875 9.29244 18.2025C9.02994 17.9437 8.82744 17.6812 8.90619 17.3212C9.34119 15.2962 9.34494 13.2037 8.91744 11.175L8.85744 10.8937C8.79369 10.5825 8.94369 10.2637 9.22494 10.1175L9.39369 10.0312C11.4112 8.98496 12.8662 5.96996 12.9899 4.72871C13.1137 3.48746 13.6574 3.14246 14.5237 3.14246C15.2774 3.14246 15.7349 3.95996 15.8849 4.82996C16.0724 5.90246 15.9112 7.55621 15.2062 8.93996C15.0224 9.29621 15.2699 9.71621 15.6674 9.72371L20.5162 9.82121ZM3.14625 19.44C4.11375 19.5937 5.1 19.6162 6.075 19.515V19.5225C6.75 19.4325 7.28625 18.9075 7.395 18.2362C7.81875 15.6 7.81875 12.915 7.395 10.2825C7.28625 9.60748 6.75 9.08623 6.075 8.99623C5.1 8.89123 4.11375 8.91748 3.14625 9.07123L2.98125 9.09748C2.76375 9.12748 2.59125 9.29623 2.56125 9.51373C2.11875 12.66 2.11875 15.8512 2.56125 18.9975C2.59125 19.215 2.76375 19.3837 2.98125 19.4137L3.14625 19.44Z"
      />
    </svg>
  );
};
